<template>
  <div class="px-3">
    <vs-card>
      <div class="d-flex justify-content-between align-items-center w-100 my-3">
        <h4
          class="mb-0 ml-2 text-uppercase"
          :style="{ color: cor2 }"
        >
          Meus projetos
        </h4>

        <vs-button
          v-if="ownerId === 0"
          class="mr-3"
          :color="cor1"
          text-color="white"
          icon="add"
          @click="createNewSurvey"
        >
          Novo Survey
        </vs-button>
      </div>

      <hr class="custom-hr">

      <vs-row>
        <vs-col
          :key="key"
          height="900px"
          vs-lg="12"
          type="flex"
        >
          <div>
            <vs-collapse accordion>
              <div
                v-for="(type, i) in surveyTypes"
                :key="i"
              >
                <vs-collapse-item
                  v-if="surveysFilteredByType(type.typeId).length"
                  icon-arrow="add"
                >
                  <div
                    slot="header"
                    class="pb-2 text-uppercase font-weight-bold border-bottom"
                  >
                    {{ type.typeDesc }}
                  </div>

                  <vs-table
                    v-model="selected"
                    class="pl-1"
                    :data="surveysFilteredByType(type.typeId)"
                    @selected="goToSurvey"
                  >
                    <template slot="thead">
                      <vs-th
                        v-for="(header, hIdx) in headerColumns"
                        :key="hIdx"
                        :class="header === 'Status' ? 'px-3' : 'px-1'"
                      >
                        {{ header }}
                      </vs-th>
                    </template>

                    <template slot-scope="{ data }">
                      <vs-tr
                        v-for="(tr, indextr) in data"
                        :key="indextr"
                        class="border-top"
                        :data="tr"
                      >
                        <vs-td class="">
                          <div class="d-flex no-block align-items-center">
                            <div class="mr-2">
                              <img
                                :src="imgpath(tr.projectID)"
                                width="45"
                              >
                            </div>
                            <div>
                              <div class="d-flex">
                                <h5 class="mb-0 font-16 font-medium">
                                  {{ tr.surveyName + " (" + tr.surveyID + ")" }}
                                </h5>
                              </div>
                              <span>{{ tr.entityName }}</span>
                            </div>
                          </div>
                        </vs-td>
                        <vs-td>
                          <div
                            v-if="tr.srvSructRef != '0' && tr.srvSructRef != null && tr.srvSructRef != tr.projectID"
                            class="d-flex"
                          >
                            <img
                              src="https://config.fanbase.com.br/prj/0/icon-clip.png"
                              class="ml-1"
                              width="14"
                              height="14"
                            >
                            <h6 class="ml-1">
                              {{ "(" + tr.srvSructRef + ")" }}
                            </h6>
                          </div>
                        </vs-td>
                        <vs-td v-if="checkItemAccess('Editar Projetos Digitais')">
                          {{ tr.projectName + " (" + tr.projectID + ")" }}
                        </vs-td>
                        <vs-td class="">
                          {{ tr.qtTotal }}
                        </vs-td>
                        <vs-td class="">
                          {{ tr.concludedTot }}
                          <b>({{ tr.concludedPerc }}%)</b>
                        </vs-td>
                        <vs-td class="w-10">
                          <vs-chip :color="tr.phase === 'TEST' ? 'orange' : 'green'">
                            <span v-if="tr.phase === 'TEST'">Em teste</span>
                            <span v-else>Publicado</span>
                          </vs-chip>
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                </vs-collapse-item>
              </div>
            </vs-collapse>
          </div>
          <!-- Fim colapse-->
        </vs-col>
      </vs-row>
    </vs-card>

    <!-- Survey Dialog -->
    <vs-popup
      title="Adicionar novo Survey"
      :active.sync="surveyDialog"
    >
      <vs-row>
        <vs-col
          class="mt-2"
          vs-lg="10"
        >
          <label class="small font-weight-bold mt-2">Nome do Survey:</label>

          <vs-input
            v-model="obj.surveyName"
            placeholder="Nome - Evento - Grupo - mês/ano"
            class="w-100"
            :danger="invalid && !obj.surveyName"
            danger-text="Campo obrigatório"
          />
        </vs-col>

        <vs-col
          class="mt-2"
          vs-lg="6"
        >
          <label class="small font-weight-bold mt-2">Tipo de Survey:</label>

          <vs-select
            v-model="obj.Surveytype"
            class="w-100"
            :danger="invalid && !obj.Surveytype"
            danger-text="Campo obrigatório"
          >
            <vs-select-item
              v-for="(item, i) in surveyTypes"
              :key="i"
              :value="item.typeId"
              :text="item.typeId + ': ' + item.typeDesc"
            />
          </vs-select>
        </vs-col>

        <vs-col
          class="mt-2"
          vs-lg="6"
        >
          <label class="small font-weight-bold mt-2">Projeto:</label>

          <vs-select
            v-model="obj.project"
            class="w-100"
            :danger="invalid && obj.project == null"
            danger-text="Campo obrigatório"
          >
            <vs-select-item
              v-for="(item, i) in projects"
              :key="i"
              :value="item.projectId"
              :text="item.projectId + ': ' + item.projectName"
            />
          </vs-select>
        </vs-col>

        <vs-col
          class="mt-2"
          vs-lg="4"
        >
          <label class="small font-weight-bold mt-2">
            Número de repetições por usuário:
          </label>

          <vs-input
            v-model="obj.RunNumbers"
            type="number"
            class="w-100"
            :danger="invalid && !obj.RunNumbers"
            danger-text="Campo obrigatório"
          />
        </vs-col>

        <vs-col
          class="mt-2 text-center"
          vs-lg="4"
        >
          <label class="small font-weight-bold mt-2">
            Haverá avaliação ao final?
          </label>

          <vs-switch
            v-model="obj.CalculateScore"
            class="mx-auto mt-1"
            vs-icon-on="done"
          />
        </vs-col>

        <vs-col
          class="mt-2 text-center"
          vs-lg="4"
        >
          <label class="small font-weight-bold mt-2">
            Essa pesquisa deve gerar IDs?
          </label>

          <vs-switch
            v-model="obj.IdDigital"
            class="mx-auto mt-1"
            vs-icon-on="done"
          />
        </vs-col>

        <vs-col
          class="mt-2"
          vs-lg="12"
        >
          <label class=" small font-weight-bold mt-2">
            Assunto do email enviado ao final do Survey:
          </label>

          <vs-input
            v-model="obj.finalMail"
            class="w-100"
          />
        </vs-col>
      </vs-row>

      <vs-divider />

      <vs-row>
        <vs-col vs-lg="6">
          <label class="small font-weight-bold mt-2">
            Defina a estrutura:
          </label>

          <vs-select
            v-model="structure"
            class="w-100"
            @change="resetStructure"
          >
            <vs-select-item
              v-for="(item, i) in structureOptions"
              :key="i"
              :value="item.id"
              :text="item.text"
            />
          </vs-select>
        </vs-col>

        <vs-col vs-lg="6">
          <div v-if="structure === 2 || structure === 3">
            <label class="small font-weight-bold mt-2">
              <span v-if="structure === 2">Selecione o survey com a estrutura desejada:</span>
              <span v-else>Selecione o survey para criar o vínculo:</span>
            </label>

            <vs-select
              v-if="structure === 2"
              :key="count + 'survey-base'"
              v-model="obj.surveyBase"
              class="w-100"
            >
              <vs-select-item
                v-for="(item, i) in surveys"
                :key="i"
                :value="item.surveyID"
                :text="item.surveyID + ': ' + item.surveyName"
              />
            </vs-select>

            <vs-select
              v-else
              :key="count + 'struct-ref'"
              v-model="obj.surveyStructRef"
              class="w-100"
            >
              <vs-select-item
                v-for="(item, i) in surveys"
                :key="i"
                :value="item.surveyID"
                :text="item.surveyID + ': ' + item.surveyName"
              />
            </vs-select>
          </div>
        </vs-col>
      </vs-row>

      <div class="d-flex justify-content-end mt-5">
        <vs-button
          :color="cor1"
          class="mr-2"
          @click="surveyDialog = false"
        >
          Cancelar
        </vs-button>

        <vs-button
          :color="cor2"
          text-color="black"
          @click="saveSurvey"
        >
          <span>Salvar</span>
          Survey
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* Config */
import { configDomain, baseUrl } from '@/global';

/* Services */
import axios from 'axios';
import Survey from '@/services/survey';
import System from '@/services/system';

const surveyService = new Survey();
const systemService = new System();

export default {
  name: 'SurveyList',
  data: () => ({
    selected: [],
    surveys: [],
    surveyTypes: [],
    projects: [],
    obj: {},
    surveyDialog: false,
    invalid: false,
    structureOptions: [
      { id: 1, text: 'Desejo criar uma nova estrutura' },
      { id: 2, text: 'Desejo apenas copiar uma estrutura' },
      { id: 3, text: 'Desejo vincular a outro Survey' },
    ],
    structure: 0,
    count: 0,
  }),
  computed: {
    cor1() {
      return this.$store.state.configBox.cor1;
    },
    cor2() {
      return this.$store.state.configBox.cor2;
    },
    ownerId() {
      return this.$store.state.ownerID;
    },
    headerColumns() {
      if (this.checkItemAccess('Editar Projetos Digitais')) {
        return ['Questionário', 'Vinculo', 'Projeto', 'Total', 'Concluído', 'Status']
      } else {
        return ['Questionário', 'Total', 'Concluído', 'Status']
      }
    },
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  mounted() {
    this.loadSurveys();
    this.loadSurveyTypes();
    this.loadProjects();
  },
  methods: {
    loadProjects() {
      systemService.getProjectsByOwner(this.ownerId, this.token)
        .then((res) => {
          this.projects = res.data.data;
          this.projects.sort((a, b) => a.projectId - b.projectId);
        })
        .catch((err) => alert(err));
    },
    checkItemAccess(access) {
      if (!access) return true;
      return this.$store.state.loginData.accessValues.includes(access);
    },
    loadSurveys() {
      this.$store.dispatch('set_isLoadingActive', true);
      surveyService.getSurveysOwner(this.ownerId, this.token)
        .then((res) => {
          this.surveys = res.surveys;
          this.surveys.sort((a, b) => { return a.surveyID + b.surveyID })
        })
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    loadSurveyTypes() {
      surveyService.getSurveyTypes(this.token).then((res) => {
        this.surveyTypes = res;
        this.surveyTypes.sort(function (a, b) {
          return a.typeId - b.typeId;
        })
      });
    },
    imgpath(id) {
      return `${configDomain}/prj/${id}/logo.png`;
    },
    surveysFilteredByType(type) {
      if (!this.surveys.length) return [];

      return this.surveys.filter((el) => el.surveyType == type);
    },
    loadSurveyStats(id) {
      surveyService.getSurveyStatsExportUrl(id, this.token)
        .then((res) => {
          this.$store.commit('SET_surveyStats', res.data.data);
          this.$store.commit('SET_selectedSurvey', id);
          this.$router.push('/dashboards/survey-details');
        })
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    goToSurvey(item) {
      this.$store.dispatch('set_isLoadingActive', true);
      this.loadSurveyStats(item.surveyID);
    },
    createNewSurvey() {
      this.obj = {};
      this.obj.RunNumbers = 1
      this.obj.IdDigital = true
      this.invalid = false;
      this.structure = 0;
      this.surveyDialog = true;
    },
    saveSurvey() {
      this.$store.dispatch('set_isLoadingActive', true);

      if (
        !this.obj.surveyName ||
        !this.obj.Surveytype ||
        this.obj.project == null ||
        !this.obj.RunNumbers
      ) {
        this.$store.dispatch('set_isLoadingActive', false);
        this.invalid = true;
        return;
      }

      this.replaceNull();

      const object = this.obj;
      object.RunNumbers = parseInt(object.RunNumbers);

      const url = `${baseUrl}/Survey`;
      const headers = { headers: { Authorization: `Bearer ${this.token}` } };

      axios.post(url, object, headers)
        .then(() => {
          this.$vs.notify({
            title: 'Survey criado com sucesso!',
          });
        })
        .catch((err) => alert(err))
        .finally(() => {
          this.$store.dispatch('set_isLoadingActive', false);
          this.surveyDialog = false;
          this.invalid = false;
          this.loadSurveys();
        });
    },
    replaceNull() {
      if (!this.obj.CalculateScore) this.obj.CalculateScore = false;
      if (!this.obj.IdDigital) this.obj.IdDigital = false;
      if (!this.obj.surveyStructRef) this.obj.surveyStructRef = 0;
      if (!this.obj.surveyBase) this.obj.surveyBase = 0;
      if (!this.obj.finalMail || this.obj.finalMail.length === 0) {
        this.obj.finalMail = null;
      }
    },
    resetStructure() {
      delete this.obj.surveyBase;
      delete this.obj.surveyStructRef;
      this.count++;
    },
  },
};
</script>
